
<template>
  <div class="picker">
    <el-date-picker
      style="width:320px"
      v-model="timeStrList"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      @change="onChoiceDate"
      :clearable="false"
      :editable="false"
      :default-time="['00:00:00','23:59:59']"
    ></el-date-picker>
    <div v-if="picker" style="margin:0 10px;cursor: pointer;">
      <span class="item" @click="pickerOptions(0)">今天</span>
      <el-divider direction="vertical"></el-divider>
      <span class="item" @click="pickerOptions(1)">昨天</span>
      <el-divider direction="vertical"></el-divider>
      <span class="item" @click="pickerOptions(2)">近3天</span>
      <el-divider direction="vertical"></el-divider>
      <span class="item" @click="pickerOptions(6)">近7天</span>
    </div>
  </div>
</template>

<script>
import moment from 'dayjs';

export default {
  name: "reportDate",
  model: {
    prop: "value",
    event: "value-change",
  },
  props: {
    picker: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeStrList: ["", ""],
    };
  },

  methods: {
    pickerOptions(type) {
      const end = new Date();
      const start = new Date();

      if (type === 1) {
        start.setTime(start.getTime() - 3600 * 1000 * 24 * type);
        this.timeStrList = [moment(new Date(start)).format("YYYY-MM-DD 00:00:00"), moment(new Date(start)).format("YYYY-MM-DD 23:59:59")];
      } else {
        start.setTime(start.getTime() - 3600 * 1000 * 24 * type);
        this.timeStrList = [moment(new Date(start)).format("YYYY-MM-DD 00:00:00"), moment(new Date(end)).format("YYYY-MM-DD 23:59:59")];
      }
      this.type = type;
      this.$emit("change", this.timeStrList);
    },
    onChoiceDate(type, dates) {
      this.type = type;
      this.$emit("change", type, dates);
    },
  },
  mounted() {
    this.pickerOptions(6);//默认6天
  }
};
</script>

<style lang="scss" scoped>
.picker {
  display: flex;
  .item {
    line-height: 30px;
    font-size: 14px;
    color: #409eff;
  }
  ::v-deep .el-range-editor.el-input__inner {
    padding: 3px;
  }
}
</style>