<template>
  <div>
    <el-button
      :disabled="disabled"
      style="font-size: 14px;height:32px;"
      @click="exportData"
      >导出/邮件</el-button
    >
    <el-dialog
      :visible.sync="modal1"
      width="400px"
      :z-index="2000"
      title="导出/发送邮件"
      border
    >
      <el-radio-group v-model="sendType">
        <el-radio label="0">直接导出</el-radio>
        <el-radio label="1">发送邮件</el-radio>
        <el-radio label="2">导出并发送邮件</el-radio>
      </el-radio-group>
      <div style="display:flex;margin-top: 10px;">
        <span style="width:150px;">邮箱发送地址：</span>
        <el-input
          placeholder="多个邮箱地址中间用英文逗号分隔。"
          v-model="sendMails"
        ></el-input>
      </div>

      <div slot="footer">
        <el-button 
          @click="oncancel"
        >取消</el-button>
        <el-button
          @click="onok"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

/**
 * 导出
 */

import { exportExcels } from '@/api/exportReport-api';
import { saveAsFile } from '@/utils/saveAsFile';
import moment from 'dayjs';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default"
    },
    isMiles: {
      default: true,
      type: Boolean
    },
    title: {
      default: "",
      type: String
    },
    accState: {
      default: 0,
      type: Number
    },
    alarmTypes: {
      default: "",
      type: String
    },
    adasAlarmType: {
      default: "",
      type: String
    },
    signType: {
      default: 0,
      type: Number
    },
    beginOffTimeMin: {
      default: 0,
      type: Number
    },
    endOffTimeMin: {
      default: 0,
      type: Number
    },
    beginTime: {
      type: String,
      default: ""
    },
    endTime: {
      default: "",
      type: String
    },
    flag: {
      default: 0,
      type: Number
    },
    groupId: {
      default: 0,
      type: Number
    },
    userName: {
      default: '',
      type: String
    },
    min: {
      default: 0,
      type: Number
    },
    startTime: {
      default: "",
      type: String
    },
    type: {
      default: 0,
      type: Number
    },
    vehicleFlag: {
      default: 0,
      type: Number
    },
    vehicleId: {
      default: 0,
      type: Number
    },
    dayOrMonth: {
      default: 0,
      type: Number
    },
    loadStatus: {
      default: 0,
      type: Number
    },
    AParam: {
      type: Number,
      default: 0
    },
    alarmLevel: {
      type: [String, Number],
      default: ""
    },
    driverName: {
      type: String,
      default: ""
    },
    stopTime: {
      type: Number,
      default: 0
    },
    gasCard: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sendMails: "",
      sendType: "0",
      modal1: false
    };
  },
  methods: {
    exportData: function(e) {
      e.preventDefault();
      if (this.isMiles) {
        this.modal1 = true;
      } else {
        this.exportDatas();
      }
    },
    oncancel() {
      this.modal1 = false;
    },
    onok() {
      let mails = /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (
        (this.sendType * 1 == 1 || this.sendType * 1 == 2) &&
        !mails.test(this.sendMails)
      ) {
        return this.$message.info("请输入正确的邮箱格式！");
      }

      this.exportDatas();
      this.modal1 = false;
    },
    exportDatas() {
      exportExcels({
        accState: this.accState,
        alarmTypes: this.alarmTypes,
        adasAlarmType: this.adasAlarmType,
        startTime: this.startTime,
        beginTime: this.beginTime,
        endTime: this.endTime,
        flag: this.flag,
        groupId: this.groupId,
        userName: this.userName,
        type: this.type,
        vehicleFlag: this.vehicleFlag,
        vehicleId: this.vehicleId,
        beginOffTimeMin: this.beginOffTimeMin,
        endOffTimeMin: this.endOffTimeMin,
        signType: this.signType,
        dayOrMonth: this.dayOrMonth,
        loadStatus: this.loadStatus,
        sendMails: this.sendMails,
        sendType: this.sendType * 1,
        AParam: this.AParam,
        stopTime: this.stopTime,
        driverName: this.driverName,
        gasCard: this.gasCard,
        alarmLevel: this.alarmLevel
      }).then(res => {
        let urls = URL.createObjectURL(res);
        console.log(res);
        if (res.size != 0) {
          saveAsFile(
            urls,
            `${this.title}${moment(new Date()).format("YYYYMMDDHHmmss")}.xls`
          );
        } else {
          this.$message.info("发送邮件成功");
        }
      });
    }
  }
};
</script>
