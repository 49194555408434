<template>
  <!-- 操作记录 -->
  <el-container class="OperationRecord">
    <el-header height="auto">
      <div class="filter-pane">
        <label>操作类型</label>
        <el-select v-model="themaIndex"
          @change="SelectCarUser"
          style="width:130px;">
          <el-option value="用户操作记录">用户操作记录</el-option>
          <el-option value="车辆操作记录">车辆操作记录</el-option>
        </el-select>
        <label v-text="CarAndUser===false?'选择用户':'车组车辆'"
          class="labelMargin"></label>
        <div v-show="CarAndUser"
          class="reportTree">
          <SelectGroupTree style="width:300px"
            @on-select="onSelect"
            :multipleProp="{ noGroup: true }"
            @on-vehicle="onConfirm"
            @dbClick="dbConfirm"
            class="TreeWidth"
            @clear="ClearSearchParams"></SelectGroupTree>
        </div>
        <div v-show="!CarAndUser">
          <el-autocomplete style="width:180px;"
            v-model="UserName"
            :fetch-suggestions="SearchUserData"
            placeholder="请输入用户名"
            :trigger-on-focus="false"
            @select="UserSelect"></el-autocomplete>
        </div>

        <label>选择时间</label>
        <reportDate size="small"
          @change="onChoiceDate"></reportDate>

        <el-button style="font-size: 14px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="searchOperRecord(1)">搜索</el-button>
      </div>
      <div class="oper-btns">
        <ExportData title="操作记录"
          :disabled="!tablePage.total"
          :type="8"
          :startTime="searchParams.startTime"
          :endTime="searchParams.endTime"
          :flag="searchParams.flag * 1"
          :groupId="searchParams.groupId * 1"
          :userName="UserName"
          :vehicleId="searchParams.vehicleId * 1"
          :vehicleFlag="searchParams.vehicleFlag * 1"></ExportData>
      </div>
    </el-header>
    <el-main class="reportTable">
      <el-table size="mini"
        stripe
        border
        :data="data"
        v-loading="tableLoading"
        height="calc(100% - 45px)">
        <el-table-column type="index"
          width="70"
          label="序号"></el-table-column>
        <el-table-column prop="operUserName"
          width="120"
          label="操作用户"></el-table-column>
        <el-table-column prop="operType"
          width="120"
          label="操作类型"></el-table-column>
        <el-table-column prop="operTime"
          width="200"
          label="操作时间"></el-table-column>
        <el-table-column prop="name"
          width="180"
          label="用户名(设备名称、车组名)"></el-table-column>
        <el-table-column prop="remark"
          label="备注"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//选择用户
import { mapState } from 'vuex';
import { getManageUserInfo } from "@/api/getManagementData.js";
//搜索接口
import { searchOperRecord } from '@/api/All-Interface.js';
//导出接口
import ExportData from '@/components/ExportData/ExportData.vue';
export default {
  // `name`与路由保持一致
  name: 'OperationRecord',
  data() {
    return {
      themaIndex: '用户操作记录',//操作类型
      searchParams: {
        startTime: "",
        endTime: "",
        flag: 0,// 0 为查用户操作记录，1 为查车辆操作记录
        groupId: '',
        vehicleId: '',
        plate: '',
        userId: '1',
        pageSize: 20,
        pageNumber: 1,
        vehicleFlag: -1,//-1 查用户下所有的车辆操作记录，0：查车组及子车组下所有车辆的操作记录，1：查指定车辆的操作记录
      },
      CarAndUser: false,//显示选择用户还是车组车辆
      UserData: [],//用户信息集合
      UserName: '',//输入的用户名

      data: [],//数据集合
      tableLoading: false,//用于加载数据时的等待状态
      tablePage: {
        pageNumber: 1,
        pageSize: 20,
        total: 0
      }
    };
  },
  components: {
    reportDate,
    SelectGroupTree,
    ExportData
  },
  methods: {
    onChoiceDate(dates) {//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
    },
    //选择车组车辆还是用户
    SelectCarUser() {
      if (this.themaIndex === '用户操作记录') {
        this.CarAndUser = false;//显示选择用户
        this.searchParams.flag = 0;
      } else if (this.themaIndex === '车辆操作记录') {
        this.CarAndUser = true;//显示车组车辆
        this.searchParams.flag = 1;
        this.UserName = "";
      }
    },
    async SearchUserData(queryString, cb) {//输入需要查找时的用户时，这里进行筛选数据源
      this.searchParams.userId = 1;
      let userDataList;
      //进入 createFilter 函数开始筛选
      userDataList = await this.UserData.filter(p => this.createFilter(p, queryString));
      cb(userDataList);
    },
    createFilter(data, queryString) {//进行数据筛选
      //如果输入的和提示的一模一样，则直接获取其id
      if (data.value === queryString) {
        this.searchParams.userId = data.id;
      };
      if (data.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1) {
        return data;
      }
    },
    //选择用户
    UserSelect(item) {
      this.searchParams.userId = item.id;
    },

    //车组车辆搜索框选择
    onSelect(node) {
      //先进行清空
      this.searchParams.vehicleId = '';
      this.searchParams.groupId = '';
      this.searchParams.vehicleFlag = '';
      if (node.type == 2) {
        this.searchParams.vehicleId = node.vehicleId || '';
        this.searchParams.vehicleFlag = 1;
        this.searchParams.groupId = node.groupId || '';
      } else if (node.type == 1) {
        this.searchParams.groupId = node.groupId || '';
        this.searchParams.vehicleFlag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      this.searchParams.vehicleId = node.vehicleId || '';
      this.searchParams.vehicleFlag = 1;
      this.searchParams.groupId = node.groupId || '';
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = 0;
      this.searchParams.vehicleFlag = 0;
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;
      this.searchParams.vehicleFlag = -1;
    },
    //验证是否有输入车组或车辆
    verification() {
      if (this.searchParams.groupId == "" || this.searchParams.groupId == 0) {
        this.$message.info("请输入或者选择车组车辆");
        return false;
      } else {
        return true;
      };
    },
    //点击搜索
    searchOperRecord(current) {
      this.data = [];//清空数据
      if (this.CarAndUser) {//如果是需要输入车组车辆，则进行判断
        if (!this.verification()) return;//验证是否有输入车组或车辆
      } else {
        if (this.UserName.trim() === '') {
          this.searchParams.userId = this.userInfo.userId;
        }
      };

      this.tableLoading = true;//用于加载数据 未完成时 的等待状态
      searchOperRecord({
        ...this.searchParams,
        pageNumber: current,
        userName: this.UserName
      }).then((res) => {
        if (res.flag == 1) {
          this.data = res.rows;
          this.tablePage.total = res.total;
          this.tablePage.pageNumber = current;
          this.searchParams.pageNumber = current;
          if (!this.data || this.data.length <= 0) {
            this.$message.info(res.msg || "请求数据为空！");
          }
        } else {
          this.data = [];
          this.$message.error(res.msg || '查询失败');
        }
        this.tableLoading = false;//加载数据完成，则将等待状态解除
      });
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.searchOperRecord(current);
    },
  },
  mounted() {
    this.$nextTick(() => {
      getManageUserInfo({
        userId: this.userInfo.userId,
        platform: 'PC',
        _qt: new Date().getTime()
      }).then((res) => {
        if (res.flag == 1) {
          // 获取用户列表的数据
          this.UserData = res.obj.userList.map(p => {
            return { 'value': p.name, 'id': p.userId };
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    });
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.OperationRecord {
  height: 100%;
  .el-header {
    margin: 0;
    padding: 0;
    .filter-pane > label {
      margin: 0 6px 0 15px;
      .labelMargin {
        margin: 0 6px 0 0px !important;
      }
    }
    .oper-btns {
      bottom: 8px;
      right: 20px;
    }
  }
  .el-main {
    height: calc(100% - 48px) !important;
    padding: 10px 20px 0 20px;
  }
  .el-pagination {
    padding: 12px 5px 5px;
    text-align: center;
  }
  .TreeWidth {
    ::v-deep .inline-input {
      width: 280px;
    }
  }
}
</style>