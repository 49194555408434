import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);


const post = (url, data, options) => axios.post(url, data, {
  headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
  ...options
});

/**
 * 获取客户专用版本基本数据
 */
export const getCustomize = (path) => {
  return new Promise((reslove, reject) => {
    let options = {
      params: {
        flag: path ? 0 : 1,
        path: path || ''
      },
      ignoreAuth: true,
    };
    if (path) options.responseType = "blob";
    axios.get('web/login.do', options).then(res => {
      if (!res) {
        reject(res);
      } else {
        if (res instanceof Blob) {
          reslove(URL.createObjectURL(res));
        } else {
          reslove(res);
        }
      }
    });
  });
};

/**
 * 账号密码登录
 */
export const accountLogin = (params) =>
  post('login/login.do', params, {
    ignoreAuth: true,
  });

/** sessionId 登录 */
export const sessionIdLogin = (val) =>
  axios.get('login/loginUserInfo', {
    params: val,
    ignoreAuth: true,
  });


/**
 * 账号激活
 * @param {JSON} params 
 */
export const systemutilKey = (params) =>
  post('systemutil/Active.do', params, {
    ignoreAuth: true,
  });

/**
 * 获取key列表
 */
// export const getKeyList = (params) =>
//   get('systemutil/pagekey.do', { params });
export const getKeyList = (params) => { return axios.get('systemutil/pagekey.do', { params }); };

/**
 * 生成密钥
 */
export const createKey = (params) =>
  post('systemutil/getkey.do', params);

/**
 * 修改密码
 */
export const changePassword = (params) => post('user/modifyPwd.do', params);

/**
 * 获取车组数据
 */
export const loadGroups = (userId) => {
  return axios.get('vehicleGroup/getGroupsByRds.do', { params: { platform: 'PC', userId } })
    .then(res => {
      if (!(res.flag && res.obj)) return [];
      let groups = res.obj;
      groups.sort();

      let i = groups.findIndex(p => p.groupId === 0);
      if (i >= 0) groups.unshift(groups.splice(i, 1)[0]);
      return groups;
    });
};

//终端更新
export const devUpdate = (params) => {
  return axios.post('monitor/uploadTerminalNoPag.do', params, {
    transRequest: false,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

/**
 * 获取验证码
 */
export const getCode = (val) =>
  axios.get('systemutil/getCode.do',
    {
      ignoreAuth: true,
      responseType: "blob",
      params: val
    })
    .then(res => URL.createObjectURL(res));

/**
* 嘉羊出货单查询
* @param {JSON} val
*/
export const getJySalesBillInfo = (params) => {
  return axios.get('jysalesBill/getJySalesBillInfo.do', { params });
};

/**
* 嘉羊出货单更新
* @param {JSON} val
*/
export const updateJySalesBill = (params) => {
  return axios.post('jysalesBill/updateJySalesBill.do', params, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

/**
* 通过id查询指定出货单
* @param {JSON} val
*/
export const getJySalesBillInfoById = (params) => {
  return axios.post('jysalesBill/getJySalesBillInfoById.do', params);
};

/**
* 嘉羊出货单删除
* @param {JSON} val
*/
export const deleteJySalesBill = (params) => {
  return axios.post('jysalesBill/deleteJySalesBill.do', params);
};


//图片下发
export const pictureRelease = (params) => {
  return axios.post('monitor/pictureRelease.do', params, {
    transRequest: false,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

//-----------------------------------------------------------------------------------------------
//一立开始接接口

/**
* 获取用户手机号信息 
* 目前使用页面有：安全设置（ SecuritySettings.vue ）
* @param {JSON} val
*/
export const getPhoneInfo = (params) => {
  return axios.get('user/getPhoneInfo', params);
};

/**
* 新修改密码
* 目前使用页面有：安全设置（ SecuritySettings.vue ）
* @param {JSON} val
*/
export const modifyNewPwd = (params) => {
  return axios.post('user/modifyNewPwd.do', params);
};

/**
* 发送验证码
* 目前使用页面有：安全设置（ SecuritySettings.vue ）
* @param {JSON} val
*/
export const sendVerifyCode = (params) => {
  return axios.get('user/sendVerifyCode', { params });
};

/**
* 查询个人信息
* 目前使用页面有：个人信息（ PersonalInformation.vue ）
* @param {JSON} val
*/
export const getUserInfo = (params) => {
  return axios.get('user/getUserInfo.do', { params });
};

/**
* 修改个人信息
* 目前使用页面有：个人信息（ PersonalInformation.vue ）
* @param {JSON} val
*/
export const updateImageInfo = (params) => {
  return axios.post('user/updateImageInfo', params);
};

/**
 * 操作记录
 * 目前使用页面有：操作记录（ OperationRecord.vue ）
 * 
 * @param {JSON} val
 */
export const searchOperRecord = val => {
  return axios.get("OperatingRecord/searchOperRecord.do", { params: val });
};

/**
 * 工时统计
 * 目前使用页面有：工时统计（ WorkingTimeStatistics.vue ）
 * 
 * @param {JSON} val
 */
export const getAccReport = val => {
  return axios.get("accReport/getAccReport.do", { params: val });
};

/**
 * 工时统计明细
 * 目前使用页面有：工时统计（ WorkingTimeStatistics.vue ）
 * 
 * @param {JSON} val
 */
export const getAccDetail = val => {
  return axios.get("accReport/getAccDetail.do", { params: val });
};

/**
 * 视频上传列表
 * 目前使用页面有：视频上传列表（ VideoUploadList.vue ）
 * 
 * @param {JSON} val
 */
export const getDownloadInfo = val => {
  return axios.get("ftpFileInfo/getDownloadInfo.do", { params: val });
};

/**
 * 视频操作记录
 * 目前使用页面有：视频操作记录（ VideoOperationRecord.vue ）
 * 
 * @param {JSON} val
 */
export const getoperateRecord = val => {
  return axios.get("videoOperate/operateRecord.do", { params: val });
};

/**
 * 视频操作记录明细
 * 目前使用页面有：视频操作记录（ VideoOperationRecord.vue ）
 * 
 * @param {JSON} val
 */
export const getoperateRecordDetail = val => {
  return axios.get("videoOperate/operateRecordDetail.do", { params: val });
};

/**
 * 指令查询
 * 目前使用页面有：指令查询（ CommandQuery.vue ）
 * 
 * @param {JSON} val
 */
export const getCommandReport = val => {
  return axios.get("commandReport/getCommandReport.do", { params: val });
};

/**
 * 车辆授权记录
 * 目前使用页面有：车辆授权记录（ AutomaticVehicleAuthorizationRecord.vue ）
 * 
 * @param {JSON} val
 */
export const getpagevehicleAuthor = val => {
  return axios.get("rechargeUser/pagevehicleAuthor.do", { params: val });
};

/**
 * 图片查询
 * 目前使用页面有：图片查询（ ImageQuery.vue ）
 * 
 * @param {JSON} val
 */
export const getPhotograph = val => {
  return axios.get("monitor/getPhotograph.do", { params: val });
};


/**
 * 车辆授权 - 查询过期车辆
 * 目前使用页面有：车辆授权（ CarEmpower.vue ）
 * 
 * @param {JSON} val
 */
export const queryVehicleExpired = val => {
  return axios.get("rechargeUser/queryVehicleExpired.do", { params: val });
};

/**
 * 车辆授权 - 授权
 * 目前使用页面有：车辆授权-子组件（ Recharge.vue ）
 * 
 * @param {JSON} val
 */
export const vehicleRecharge = val => {
  return axios.get("rechargeUser/vehicleRecharge.do", { params: val });
};

/**
 * 车辆授权 - 授权记录
 * 目前使用页面有：车辆授权-授权记录（ CarRecord.vue ）
 * 
 * @param {JSON} val
 */
export const queryVehicleFreeLog = val => {
  return axios.get("rechargeUser/queryVehicleFreeLog.do", { params: val });
};

/**
 * 车辆授权 - 授权币划拨
 * 目前使用页面有：车辆授权-授权币划拨  CarSpendMoney.vue ）
 * 
 * @param {JSON} val
 */
export const rechargeNum = val => {
  return axios.get("rechargeUser/rechargeNum.do", { params: val });
};

/**
 * 车辆授权 - 授权币划拨记录
 * 目前使用页面有：车辆授权-授权币划拨记录  CarSpendMoneyRecord.vue ）
 * 
 * @param {JSON} val
 */
export const queryRechargeLog = val => {
  return axios.get("rechargeUser/queryRechargeLog.do", { params: val });
};


/**
 * 车辆授权 - 自定义授权
 * 批量充值返回设备分类类型
 * @param {JSON} val 
 */
export const differDevType = (val) => { return axios.get('rechargeUser/differDevType.do', { params: val }); };

/**
 *  车辆授权 - 授权记录
 * 自定义车辆充值
 * @param {JSON} val 
 */
export const batchRecharge = (val) => { return axios.get('rechargeUser/batchRecharge.do', { params: val }); };


/**
 *  车辆授权 - 授权币划拨
 * 查询用户等级
 * @param {JSON} val 
 */
export const searchUserLevel = (val) => { return axios.get('rechargeUser/searchUser1Level.do', { params: val }); };


/**
 * 获取当前用户车辆状态(在线/离线)
 * 使用地方 车辆授权
 */
export const addUpVehicleStatusCount = () => { return axios.get('monitor/addUpVehicleStatusCount.do'); };


/**
 * 使用地方 车辆授权
 * 查询用户充值信息
 * @param {JSON} val 
 */
export const queryMyState = (val) => { return axios.get('rechargeUser/queryMyState.do', { params: val }); };


/**
 * 使用地方 到期提醒
 * 查询过期信息
 * @param {JSON} val 
 */
export const getVehicleRemind = (val) => { return axios.get('remindExpire/getVehicleRemind.do', { params: val }); };

/**
 * 获取油量统计
 * @param {*} val 
 * @returns 
 */
export const oilReport = (val) => { return axios2.get('oilReport/get.do', { params: val }); };